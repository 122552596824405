<template>
  <v-row>
    <v-dialog
      persistent
      v-if="manageTagsDialog"
      v-model="manageTagsDialog"
      max-width="500px"
    >
      <ManageTagsDialog
        v-model="manageTagsDialog"
        @update="refreshTags()"
        :endUsers="endUsers"
        :teamMembers="teamMembers"
        :teamTags="teamTags"
      />
    </v-dialog>
    <v-col cols="12">
      <v-card flat :disabled="trialExpiredOrAssessmentsExceeded">
        <alert-message
          v-if="!isSuperAdmin"
          :message="$t('account.team.adminMessage')"
          type="info"
        ></alert-message>
        <alert-message
          v-if="errorMessage"
          :message="errorMessage"
          type="error"
        ></alert-message>
        <alert-message
          v-if="successMessage"
          :message="successMessage"
          type="success"
        ></alert-message>
        <v-dialog v-if="inviteDialog" v-model="inviteDialog" max-width="500px">
          <InviteMember
            :selectedUser="selectedUser"
            v-model="inviteDialog"
            @update="refreshTeamMembers()"
          />
        </v-dialog>
        <v-dialog
          v-if="inviteFileDialog"
          v-model="inviteFileDialog"
          max-width="750px"
          persistent
        >
          <AddUsersByFile
            v-model="inviteFileDialog"
            :teamMembers="teamMembers"
            @success="handleInviteViaFileSuccess($event)"
            @refresh="refreshTeamMembers()"
            :endUsers="endUsers"
          />
        </v-dialog>
        <TeamSettings class="py-4" :disabled="!isSuperAdmin" />
        <div class="mt-6" v-if="showTeamSeats">
          <p class="text-h5">
            {{ $t("account.team.userSettings.usersTitle") }}
          </p>
        </div>
        <v-row justify="space-between" align="center">
          <v-col cols="12" md="6" class="text-start fill-height">
            <v-row
              v-if="showTeamSeats"
              no-gutters
              align="center"
              class="primary--text userCountBackground pa-6"
            >
              {{ $t("account.team.userSettings.totalSeats.preText") }}
              <span class="font-weight-bold mx-1">{{
                $tc(
                  "account.team.userSettings.totalSeats.totalUsersText",
                  totalUniqueUsers,
                  {
                    0: totalUniqueUsers
                  }
                )
              }}</span>
              {{
                $tc(
                  "account.team.userSettings.totalSeats.postText",
                  totalUniqueUsers,
                  {
                    1: totalAdminsText,
                    2: totalBasicUsersText
                  }
                )
              }}
            </v-row>
          </v-col>
          <v-col cols="auto" class="fill-height mt-2" align-self="center"
            ><v-btn
              v-if="teamIdpName"
              class="mb-2 mr-5 text-none"
              :disabled="!currentUserAdmin"
              @click="openFileInviteDialog"
              color="primary"
              outlined
            >
              <v-icon left color="primary">mdi-cloud-upload-outline</v-icon>
              {{ $t("account.team.userSettings.uploadCsv") }}
            </v-btn>
            <v-btn
              class="mb-2 mr-5 text-none"
              :disabled="disableAddMember"
              @click="openInviteDialog"
              color="primary"
              outlined
            >
              <v-icon left color="primary">mdi-plus</v-icon>
              {{ $t("account.team.inviteMember") }}
            </v-btn>
            <v-btn
              class="mb-2 mr-5 text-none"
              :disabled="!currentUserAdmin"
              @click="manageTagsDialog = true"
              color="primary"
              outlined
            >
              <v-icon left color="primary">mdi-tag-outline</v-icon>
              {{ $t("buttons.manageTags") }}
            </v-btn></v-col
          >
        </v-row>
        <v-card flat>
          <v-card-title class="ma-0 pa-0 text-h6">
            <v-row justify="space-around" align="end"
              ><v-col
                :cols="isSuperAdmin || $vuetify.breakpoint.xs ? '12' : ''"
              >
                {{ $t("account.team.userSettings.adminsTitle") }}</v-col
              >
              <v-col
                v-if="isSuperAdmin"
                :cols="$vuetify.breakpoint.xs ? '12' : ''"
                ><v-tabs
                  v-if="isSuperAdmin"
                  v-model="adminTableTab"
                  color="primary"
                >
                  <v-tab href="#activeAdmins" class="text-subtitle-2">
                    {{ $t("buttons.active") }}
                  </v-tab>
                  <v-tab href="#inactiveAdmins" class="text-subtitle-2">
                    {{ $t("buttons.inactive") }}
                  </v-tab>
                </v-tabs></v-col
              >
              <v-col>
                <v-text-field
                  v-model="adminSearch"
                  append-icon="search"
                  :label="$t('textFields.search')"
                  single-line
                  hide-details
                ></v-text-field
              ></v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="
              showingActiveMembers
                ? memberTableHeaders
                : inactiveMemberTableHeaders
            "
            :items="showingActiveMembers ? teamMembers : inactiveMembers"
            :loading="teamDataLoading"
            :search="adminSearch"
            :custom-filter="customFilter"
          >
            <template v-slot:item.email.value="{ item }">
              <span class="data-hj-suppress">
                {{ item.email.value }}
              </span>
            </template>
            <template v-slot:item.role="{ item }">
              <span>
                {{ apiRoles[item.role] }}
              </span>
            </template>
            <template v-slot:item.actions="{ item }">
              <div v-if="canDeleteUser(item)">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="
                        !canDeleteUser(item) || !isSuperAdmin || item.scimUser
                      "
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-if="item.status === 'Pending'"
                      @click="resendInvitation(item)"
                    >
                      <v-list-item-title>{{
                        $t("buttons.resend")
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="item.status === 'Accepted'"
                      :disabled="!currentUserAdmin"
                      @click="editUser(item)"
                    >
                      <v-list-item-title>{{
                        $t("account.team.dropDownEdit")
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteMember(item)">
                      <v-list-item-title>{{
                        ssoSignIn
                          ? $t("account.team.dropDownRevoke")
                          : $t("buttons.delete")
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
            <template v-slot:item.tags="{ item }">
              <span>
                <v-chip
                  v-for="tag in item.tags"
                  :key="tag.id"
                  class="ma-1"
                  outlined
                  :color="
                    tag.name.toLowerCase() == 'everything'
                      ? 'accent'
                      : 'primary'
                  "
                  small
                >
                  <div class="chipText">
                    {{
                      tag.name.toLowerCase() == "everything"
                        ? $t("tagDropDown.everythingTag")
                        : tag.name
                    }}
                  </div>
                </v-chip>
                <v-chip
                  v-if="item.tags.length == 0"
                  class="ma-1"
                  outlined
                  color="disabled"
                  small
                >
                  <div class="chipText">
                    {{ $t("account.team.nothingDataAccess") }}
                  </div>
                </v-chip>
              </span>
            </template>
            <template v-slot:item.privacy="{ item }">
              <v-simple-checkbox
                :ripple="false"
                :value="item.role !== 20"
                color="secondary"
                style="cursor: default"
              ></v-simple-checkbox>
            </template>

            <template v-slot:item.managedUser="{ item }">
              <v-tooltip left max-width="250" :disabled="!item.scimUser">
                <template v-slot:activator="{ on, attrs }">
                  <v-simple-checkbox
                    v-on="on"
                    v-bind="attrs"
                    :ripple="false"
                    :value="item.scimUser"
                    color="secondary"
                    style="cursor: default"
                  ></v-simple-checkbox>
                </template>
                <span>{{
                  $t("account.team.userSettings.managedUserExplanation")
                }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>

        <v-card flat class="pa-0">
          <v-card-title class="ma-0 pa-0 text-h6">
            <v-row justify="space-around" align="end"
              ><v-col
                :cols="isSuperAdmin || $vuetify.breakpoint.xs ? '12' : ''"
              >
                {{ $t("account.team.userSettings.basicUsersTitle") }}</v-col
              >
              <v-col
                v-if="isSuperAdmin"
                :cols="$vuetify.breakpoint.xs ? '12' : ''"
                ><v-tabs
                  v-if="isSuperAdmin"
                  v-model="userTableTab"
                  color="primary"
                >
                  <v-tab href="#activeEndUsers" class="text-subtitle-2">
                    {{ $t("buttons.active") }}
                  </v-tab>
                  <v-tab href="#inactiveEndUsers" class="text-subtitle-2">
                    {{ $t("buttons.inactive") }}
                  </v-tab>
                </v-tabs></v-col
              >
              <v-col>
                <v-text-field
                  v-model="endUserSearch"
                  append-icon="search"
                  :label="$t('textFields.search')"
                  single-line
                  hide-details
                ></v-text-field
              ></v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="
              showingActiveEndUsers
                ? userTableHeaders
                : inactiveUserTableHeaders
            "
            :items="showingActiveEndUsers ? endUsers : inactiveUsers"
            :loading="userDataLoading"
            :sort-by="['email']"
            :sort-desc="[false]"
            :search="endUserSearch"
            :custom-filter="customFilter"
          >
            <template v-slot:item.name="{ item }">
              <span class="data-hj-suppress">
                {{ item.name }}
              </span>
            </template>
            <template v-slot:item.email.value="{ item }">
              <span class="data-hj-suppress">
                {{ item.email.value }}
              </span>
            </template>
            <template v-slot:item.tags="{ item }">
              <table-tags-drop-down
                :tags="teamTags"
                :item="item"
                manageTags
                editable
                addMultipleTags
                :disabled="
                  (!isSuperAdmin && !allowAdminEditingUserTags) || item.scimUser
                "
                @showManageTags="manageTagsDialog = true"
                @error="errorMessage = $event"
              />
            </template>

            <template v-slot:item.actions="{ item }">
              <div v-if="!userIsTeamCreator(item)">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      :disabled="!isSuperAdmin || item.scimUser"
                    >
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="editUser(item)">
                      <v-list-item-title>{{
                        $t("account.team.dropDownEdit")
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteUser(item)">
                      <v-list-item-title>{{
                        $t("buttons.delete")
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>

            <template v-slot:item.managedUser="{ item }">
              <v-tooltip left max-width="250" :disabled="!item.scimUser">
                <template v-slot:activator="{ on, attrs }">
                  <v-simple-checkbox
                    v-on="on"
                    v-bind="attrs"
                    :ripple="false"
                    :value="item.scimUser"
                    color="secondary"
                    style="cursor: default"
                  ></v-simple-checkbox>
                </template>
                <span>{{
                  $t("account.team.userSettings.managedUserExplanation")
                }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import AlertMessage from "./AlertMessage";
import {
  cancelTeamMemberInvite,
  createTeamMemberUser,
  deleteEndUser,
  getEndUsers,
  getTeamMembers,
  getTeamTags,
  markTeamMemberAsClosed,
  getInactiveTeamMembers,
  getInactiveEndUsers
} from "../../customApi";

const InviteMember = () => import("./InviteMember");
const AddUsersByFile = () => import("./AddUsersByFile");
const TableTagsDropDown = () => import("@/components/common/TableTagsDropDown");
const ManageTagsDialog = () =>
  import("@/components/common/assessment-history/ManageTagsDialog");
const TeamSettings = () => import("./TeamSettings");

export default {
  components: {
    AlertMessage,
    InviteMember,
    AddUsersByFile,
    VitrueTooltip,
    TableTagsDropDown,
    ManageTagsDialog,
    TeamSettings
  },
  data() {
    return {
      teamDataLoading: false,
      userDataLoading: false,
      currentUserAdmin: false,
      inviteDialog: false,
      inviteFileDialog: false,
      selectedUser: null,
      apiStatuses: { 0: "Pending", 10: "Accepted" },
      apiRoles: { 0: "Admin", 10: "Super Admin", 20: "Admin" },
      inactiveMemberTableHeaders: [
        {
          text: this.$t("account.team.table.email"),
          value: "email.value",
          width: "50%"
        },
        {
          text: this.$t("account.team.table.role"),
          value: "role",
          width: "50%"
        }
      ],
      inactiveUserTableHeaders: [
        {
          text: this.$t("account.team.table.email"),
          value: "email.value",
          width: "50%"
        },
        {
          text: this.$t("account.team.table.name"),
          value: "name",
          width: "50%"
        }
      ],
      errorMessage: "",
      successMessage: "",
      teamMembers: [],
      inactiveMembers: [],
      endUsers: [],
      inactiveUsers: [],
      teamTags: [],
      manageTagsDialog: false,
      adminSearch: "",
      endUserSearch: "",
      adminTableTab: "activeAdmins",
      userTableTab: "activeEndUsers"
    };
  },
  async mounted() {
    await this.getAllUsers();
    await this.getTeamTags();
  },
  computed: {
    ...mapGetters([
      "userEmail",
      "teamMemberId",
      "currentUserTeamAndSubscriptionBasicInformation",
      "isSuperAdmin",
      "disableEmails",
      "ssoSignIn",
      "trialExpiredOrAssessmentsExceeded",
      "showTeamSeats",
      "teamIdpName",
      "allowAdminEditingUserTags"
    ]),
    userTableHeaders() {
      var headers = [
        {
          text: this.$t("account.team.table.email"),
          value: "email.value",
          width: "20%"
        },
        {
          text: this.$t("account.team.table.name"),
          value: "name",
          width: "20%"
        },
        {
          text: this.$t("account.team.table.tag"),
          value: "tags",
          width: "20%"
        }
      ];
      if (this.endUsers.some(x => x.scimUser)) {
        headers.push({
          text: this.$t("account.team.table.managedUser"),
          value: "managedUser",
          sortable: false,
          width: "5%"
        });
      }

      headers.push({
        text: this.$t("account.team.table.actions"),
        value: "actions",
        sortable: false,
        width: "5%"
      });

      return headers;
    },
    memberTableHeaders() {
      var headers = [
        {
          text: this.$t("account.team.table.email"),
          value: "email.value",
          width: "25%"
        },
        {
          text: this.$t("account.team.table.role"),
          value: "role",
          width: "20%"
        },
        {
          text: this.$t("account.team.table.access"),
          value: "tags",
          width: "25%"
        },
        {
          text: this.$t("account.team.table.privacy"),
          value: "privacy",
          width: "20%"
        }
      ];

      if (this.teamMembers.some(x => x.scimUser)) {
        headers.push({
          text: this.$t("account.team.table.managedUser"),
          value: "managedUser",
          sortable: false,
          width: "10%"
        });
      }

      headers.push({
        text: this.$t("account.team.table.actions"),
        value: "actions",
        sortable: false,
        width: "10%"
      });

      return headers;
    },
    showingActiveMembers() {
      return this.adminTableTab === "activeAdmins";
    },
    showingActiveEndUsers() {
      return this.userTableTab === "activeEndUsers";
    },
    disableAddMember() {
      return !this.currentUserAdmin || this.disableEmails;
    },
    totalUniqueUsers() {
      let adminEmails = this.getEmails(this.teamMembers);
      let userEmails = this.getEmails(this.endUsers);
      return [...new Set([...adminEmails, ...userEmails])].length;
    },
    totalAdmins() {
      let adminEmails = this.getEmails(this.teamMembers);
      return adminEmails.length;
    },
    totalBasicUsers() {
      let adminEmails = this.getEmails(this.teamMembers);
      let userEmails = this.getEmails(this.endUsers);
      let nonAdminUsers = userEmails.filter(u => {
        return !adminEmails.find(a => a === u);
      });
      return nonAdminUsers.length;
    },
    totalAdminsText() {
      return this.$tc(
        "account.team.userSettings.totalSeats.totalAdminsText",
        this.totalAdmins,
        { 0: this.totalAdmins }
      );
    },
    totalBasicUsersText() {
      return this.$tc(
        "account.team.userSettings.totalSeats.totalBasicUsersText",
        this.totalBasicUsers,
        { 0: this.totalBasicUsers }
      );
    }
  },
  watch: {
    inviteDialog() {
      if (!this.inviteDialog) {
        this.selectedUser = null;
      }
    }
  },
  methods: {
    ...mapMutations(["addAsyncRequest", "removeAsyncRequest"]),
    getEmails(userList) {
      return userList.map(x => x.email.value);
    },
    refreshTeamMembers() {
      this.getAllUsers();
    },
    async refreshTags() {
      this.refreshTeamMembers();
      await this.getTeamTags();
    },
    resetMessages() {
      this.errorMessage = "";
      this.successMessage = "";
    },
    openInviteDialog() {
      this.resetMessages();
      this.inviteDialog = true;
    },
    openFileInviteDialog() {
      this.resetMessages();
      this.inviteFileDialog = true;
    },
    async deleteMember(user) {
      this.resetMessages();
      var dialogMessage = this.ssoSignIn
        ? this.$t("account.team.revokeMemberPermissionsConfirmation")
        : this.$t("account.team.deleteMemberConfirmation");
      confirm(dialogMessage) && (await this.deleteTeamMember(user));
    },
    async deleteUser(user) {
      this.resetMessages();
      confirm(this.$t("account.team.deleteUserConfirmation")) &&
        (await this.deleteEndUser(user));
    },
    async deleteTeamMember(user) {
      try {
        this.addAsyncRequest();
        // if user had accepted OR has been invited to team and didn't have a previous user => disable and mark as closed
        // if user is part of a different team and has been invited by this one, then remove the userMoveRequestBy field
        if (user.status === "Accepted" || !user.userMoveRequestedBy.value) {
          await markTeamMemberAsClosed(user.id);
        } else {
          await cancelTeamMemberInvite(user.id);
        }

        const index = this.teamMembers.indexOf(user);
        this.teamMembers.splice(index, 1);
        this.removeAsyncRequest();
        this.successMessage = this.ssoSignIn
          ? this.$t("account.team.revokeMemberPermissionsSuccess")
          : this.$t("account.team.deleteMemberSuccess");
        this.refreshTeamMembers();
      } catch (err) {
        this.errorMessage = this.ssoSignIn
          ? this.$t("account.team.revokeMemberPermissionsError")
          : this.$t("account.team.deleteMemberError");

        this.removeAsyncRequest();
      }
    },
    async getAllUsers() {
      this.getTeamMembers();
      this.getEndUsers();
    },
    async getEndUsers() {
      this.userDataLoading = true;
      try {
        this.endUsers = await getEndUsers();
        if (this.isSuperAdmin) {
          this.inactiveUsers = await getInactiveEndUsers();
        }
      } catch (err) {
        this.errorMessage = this.$t("genericError");
      } finally {
        this.userDataLoading = false;
      }
    },
    async getTeamMembers() {
      try {
        this.teamDataLoading = true;
        let result = await getTeamMembers();

        // set if current user is an admin
        var currentMember = result.find(obj => {
          return obj.email.value === this.userEmail;
        });
        if (currentMember) {
          this.currentUserAdmin =
            currentMember.role === 10 || this.isSuperAdmin;
        }

        this.teamMembers = result.map(a => {
          a.status = this.apiStatuses[a.status];
          return a;
        });

        if (this.isSuperAdmin) {
          this.inactiveMembers = await getInactiveTeamMembers();
        }
      } catch (err) {
      } finally {
        this.teamDataLoading = false;
      }
    },
    async resendInvitation(member) {
      this.resetMessages();
      try {
        let userToCreateDto = {
          username: member.email.value,
          email: { value: member.email.value },
          resendInvitation: true
        };
        await createTeamMemberUser(this.teamMemberId, userToCreateDto);
        this.successMessage = this.$t("account.team.invitationSuccess");
      } catch (err) {
        this.errorMessage = this.$t("account.team.invitationError");
      }
    },
    canDeleteUser(user) {
      return (
        !this.userIsTeamCreator(user) && user.email.value !== this.userEmail
      );
    },
    userIsTeamCreator(user) {
      return (
        user.email.value ===
        this.currentUserTeamAndSubscriptionBasicInformation?.primaryAccountEmail
          ?.value
      );
    },
    async getTeamTags() {
      try {
        let dto = {
          everythingTag: true,
          allTeamTags: true
        };
        let tags = (await getTeamTags(dto)) || [];
        this.teamTags = [...tags];
      } catch (err) {
        this.teamTags = [];
      }
    },
    async deleteEndUser(user) {
      let initialCount = this.endUsers.length;
      let index = 0;
      try {
        await deleteEndUser(user);
        index = this.endUsers.indexOf(user);
        this.endUsers.splice(index, 1);
        this.successMessage = this.$t("account.team.deleteEndUserSuccess");
        this.refreshTeamMembers();
      } catch (err) {
        if (initialCount > this.endUsers.length) {
          this.endUsers.splice(index, 0, user);
        }
        this.errorMessage = this.$t("account.team.deleteMemberError");
      }
    },
    editUser(user) {
      this.selectedUser = user;
      this.openInviteDialog();
    },
    handleInviteViaFileSuccess(message) {
      this.successMessage = message;
      this.refreshTeamMembers();
    },
    customFilter(value, search, item) {
      var searchLowerCase = search.toLowerCase();
      let tagNames = item.tags.map(x => x.name).join();
      let itemsToSearch = [
        item.email.value,
        item.name ?? "",
        this.apiRoles[item.role] ?? "",
        tagNames
      ];
      let matches = itemsToSearch.filter(x => {
        return x.toLowerCase().includes(searchLowerCase);
      });
      return matches.length > 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.customButton {
  color: #63a8ff;
  height: 22px !important;
}
.noClick {
  cursor: default;
}
.userCountBackground {
  background-color: var(--v-selectedPrimary-base);
}
</style>
