var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _vm.manageTagsDialog
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "500px" },
              model: {
                value: _vm.manageTagsDialog,
                callback: function($$v) {
                  _vm.manageTagsDialog = $$v
                },
                expression: "manageTagsDialog"
              }
            },
            [
              _c("ManageTagsDialog", {
                attrs: {
                  endUsers: _vm.endUsers,
                  teamMembers: _vm.teamMembers,
                  teamTags: _vm.teamTags
                },
                on: {
                  update: function($event) {
                    return _vm.refreshTags()
                  }
                },
                model: {
                  value: _vm.manageTagsDialog,
                  callback: function($$v) {
                    _vm.manageTagsDialog = $$v
                  },
                  expression: "manageTagsDialog"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-card",
            {
              attrs: {
                flat: "",
                disabled: _vm.trialExpiredOrAssessmentsExceeded
              }
            },
            [
              !_vm.isSuperAdmin
                ? _c("alert-message", {
                    attrs: {
                      message: _vm.$t("account.team.adminMessage"),
                      type: "info"
                    }
                  })
                : _vm._e(),
              _vm.errorMessage
                ? _c("alert-message", {
                    attrs: { message: _vm.errorMessage, type: "error" }
                  })
                : _vm._e(),
              _vm.successMessage
                ? _c("alert-message", {
                    attrs: { message: _vm.successMessage, type: "success" }
                  })
                : _vm._e(),
              _vm.inviteDialog
                ? _c(
                    "v-dialog",
                    {
                      attrs: { "max-width": "500px" },
                      model: {
                        value: _vm.inviteDialog,
                        callback: function($$v) {
                          _vm.inviteDialog = $$v
                        },
                        expression: "inviteDialog"
                      }
                    },
                    [
                      _c("InviteMember", {
                        attrs: { selectedUser: _vm.selectedUser },
                        on: {
                          update: function($event) {
                            return _vm.refreshTeamMembers()
                          }
                        },
                        model: {
                          value: _vm.inviteDialog,
                          callback: function($$v) {
                            _vm.inviteDialog = $$v
                          },
                          expression: "inviteDialog"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.inviteFileDialog
                ? _c(
                    "v-dialog",
                    {
                      attrs: { "max-width": "750px", persistent: "" },
                      model: {
                        value: _vm.inviteFileDialog,
                        callback: function($$v) {
                          _vm.inviteFileDialog = $$v
                        },
                        expression: "inviteFileDialog"
                      }
                    },
                    [
                      _c("AddUsersByFile", {
                        attrs: {
                          teamMembers: _vm.teamMembers,
                          endUsers: _vm.endUsers
                        },
                        on: {
                          success: function($event) {
                            return _vm.handleInviteViaFileSuccess($event)
                          },
                          refresh: function($event) {
                            return _vm.refreshTeamMembers()
                          }
                        },
                        model: {
                          value: _vm.inviteFileDialog,
                          callback: function($$v) {
                            _vm.inviteFileDialog = $$v
                          },
                          expression: "inviteFileDialog"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("TeamSettings", {
                staticClass: "py-4",
                attrs: { disabled: !_vm.isSuperAdmin }
              }),
              _vm.showTeamSeats
                ? _c("div", { staticClass: "mt-6" }, [
                    _c("p", { staticClass: "text-h5" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("account.team.userSettings.usersTitle")
                          ) +
                          " "
                      )
                    ])
                  ])
                : _vm._e(),
              _c(
                "v-row",
                { attrs: { justify: "space-between", align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-start fill-height",
                      attrs: { cols: "12", md: "6" }
                    },
                    [
                      _vm.showTeamSeats
                        ? _c(
                            "v-row",
                            {
                              staticClass:
                                "primary--text userCountBackground pa-6",
                              attrs: { "no-gutters": "", align: "center" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "account.team.userSettings.totalSeats.preText"
                                    )
                                  ) +
                                  " "
                              ),
                              _c(
                                "span",
                                { staticClass: "font-weight-bold mx-1" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tc(
                                        "account.team.userSettings.totalSeats.totalUsersText",
                                        _vm.totalUniqueUsers,
                                        { 0: _vm.totalUniqueUsers }
                                      )
                                    )
                                  )
                                ]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$tc(
                                      "account.team.userSettings.totalSeats.postText",
                                      _vm.totalUniqueUsers,
                                      {
                                        1: _vm.totalAdminsText,
                                        2: _vm.totalBasicUsersText
                                      }
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "fill-height mt-2",
                      attrs: { cols: "auto", "align-self": "center" }
                    },
                    [
                      _vm.teamIdpName
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mb-2 mr-5 text-none",
                              attrs: {
                                disabled: !_vm.currentUserAdmin,
                                color: "primary",
                                outlined: ""
                              },
                              on: { click: _vm.openFileInviteDialog }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { left: "", color: "primary" } },
                                [_vm._v("mdi-cloud-upload-outline")]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "account.team.userSettings.uploadCsv"
                                    )
                                  ) +
                                  " "
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mb-2 mr-5 text-none",
                          attrs: {
                            disabled: _vm.disableAddMember,
                            color: "primary",
                            outlined: ""
                          },
                          on: { click: _vm.openInviteDialog }
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { left: "", color: "primary" } },
                            [_vm._v("mdi-plus")]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("account.team.inviteMember")) +
                              " "
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mb-2 mr-5 text-none",
                          attrs: {
                            disabled: !_vm.currentUserAdmin,
                            color: "primary",
                            outlined: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.manageTagsDialog = true
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { left: "", color: "primary" } },
                            [_vm._v("mdi-tag-outline")]
                          ),
                          _vm._v(
                            " " + _vm._s(_vm.$t("buttons.manageTags")) + " "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "ma-0 pa-0 text-h6" },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "space-around", align: "end" } },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols:
                                  _vm.isSuperAdmin || _vm.$vuetify.breakpoint.xs
                                    ? "12"
                                    : ""
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "account.team.userSettings.adminsTitle"
                                    )
                                  )
                              )
                            ]
                          ),
                          _vm.isSuperAdmin
                            ? _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: _vm.$vuetify.breakpoint.xs ? "12" : ""
                                  }
                                },
                                [
                                  _vm.isSuperAdmin
                                    ? _c(
                                        "v-tabs",
                                        {
                                          attrs: { color: "primary" },
                                          model: {
                                            value: _vm.adminTableTab,
                                            callback: function($$v) {
                                              _vm.adminTableTab = $$v
                                            },
                                            expression: "adminTableTab"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-tab",
                                            {
                                              staticClass: "text-subtitle-2",
                                              attrs: { href: "#activeAdmins" }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("buttons.active")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-tab",
                                            {
                                              staticClass: "text-subtitle-2",
                                              attrs: { href: "#inactiveAdmins" }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("buttons.inactive")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "append-icon": "search",
                                  label: _vm.$t("textFields.search"),
                                  "single-line": "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.adminSearch,
                                  callback: function($$v) {
                                    _vm.adminSearch = $$v
                                  },
                                  expression: "adminSearch"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.showingActiveMembers
                        ? _vm.memberTableHeaders
                        : _vm.inactiveMemberTableHeaders,
                      items: _vm.showingActiveMembers
                        ? _vm.teamMembers
                        : _vm.inactiveMembers,
                      loading: _vm.teamDataLoading,
                      search: _vm.adminSearch,
                      "custom-filter": _vm.customFilter
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.email.value",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("span", { staticClass: "data-hj-suppress" }, [
                              _vm._v(" " + _vm._s(item.email.value) + " ")
                            ])
                          ]
                        }
                      },
                      {
                        key: "item.role",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("span", [
                              _vm._v(
                                " " + _vm._s(_vm.apiRoles[item.role]) + " "
                              )
                            ])
                          ]
                        }
                      },
                      {
                        key: "item.actions",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm.canDeleteUser(item)
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: { "offset-y": "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              !_vm.canDeleteUser(
                                                                item
                                                              ) ||
                                                              !_vm.isSuperAdmin ||
                                                              item.scimUser,
                                                            icon: ""
                                                          }
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-dots-horizontal"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          { attrs: { dense: "" } },
                                          [
                                            item.status === "Pending"
                                              ? _c(
                                                  "v-list-item",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.resendInvitation(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "buttons.resend"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.status === "Accepted"
                                              ? _c(
                                                  "v-list-item",
                                                  {
                                                    attrs: {
                                                      disabled: !_vm.currentUserAdmin
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.editUser(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "account.team.dropDownEdit"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "v-list-item",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteMember(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.ssoSignIn
                                                        ? _vm.$t(
                                                            "account.team.dropDownRevoke"
                                                          )
                                                        : _vm.$t(
                                                            "buttons.delete"
                                                          )
                                                    )
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "item.tags",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "span",
                              [
                                _vm._l(item.tags, function(tag) {
                                  return _c(
                                    "v-chip",
                                    {
                                      key: tag.id,
                                      staticClass: "ma-1",
                                      attrs: {
                                        outlined: "",
                                        color:
                                          tag.name.toLowerCase() == "everything"
                                            ? "accent"
                                            : "primary",
                                        small: ""
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "chipText" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              tag.name.toLowerCase() ==
                                                "everything"
                                                ? _vm.$t(
                                                    "tagDropDown.everythingTag"
                                                  )
                                                : tag.name
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                }),
                                item.tags.length == 0
                                  ? _c(
                                      "v-chip",
                                      {
                                        staticClass: "ma-1",
                                        attrs: {
                                          outlined: "",
                                          color: "disabled",
                                          small: ""
                                        }
                                      },
                                      [
                                        _c("div", { staticClass: "chipText" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "account.team.nothingDataAccess"
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              2
                            )
                          ]
                        }
                      },
                      {
                        key: "item.privacy",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("v-simple-checkbox", {
                              staticStyle: { cursor: "default" },
                              attrs: {
                                ripple: false,
                                value: item.role !== 20,
                                color: "secondary"
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "item.managedUser",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  left: "",
                                  "max-width": "250",
                                  disabled: !item.scimUser
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-simple-checkbox",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticStyle: {
                                                    cursor: "default"
                                                  },
                                                  attrs: {
                                                    ripple: false,
                                                    value: item.scimUser,
                                                    color: "secondary"
                                                  }
                                                },
                                                "v-simple-checkbox",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "account.team.userSettings.managedUserExplanation"
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "pa-0", attrs: { flat: "" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "ma-0 pa-0 text-h6" },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "space-around", align: "end" } },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols:
                                  _vm.isSuperAdmin || _vm.$vuetify.breakpoint.xs
                                    ? "12"
                                    : ""
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "account.team.userSettings.basicUsersTitle"
                                    )
                                  )
                              )
                            ]
                          ),
                          _vm.isSuperAdmin
                            ? _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: _vm.$vuetify.breakpoint.xs ? "12" : ""
                                  }
                                },
                                [
                                  _vm.isSuperAdmin
                                    ? _c(
                                        "v-tabs",
                                        {
                                          attrs: { color: "primary" },
                                          model: {
                                            value: _vm.userTableTab,
                                            callback: function($$v) {
                                              _vm.userTableTab = $$v
                                            },
                                            expression: "userTableTab"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-tab",
                                            {
                                              staticClass: "text-subtitle-2",
                                              attrs: { href: "#activeEndUsers" }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("buttons.active")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-tab",
                                            {
                                              staticClass: "text-subtitle-2",
                                              attrs: {
                                                href: "#inactiveEndUsers"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("buttons.inactive")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "append-icon": "search",
                                  label: _vm.$t("textFields.search"),
                                  "single-line": "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.endUserSearch,
                                  callback: function($$v) {
                                    _vm.endUserSearch = $$v
                                  },
                                  expression: "endUserSearch"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.showingActiveEndUsers
                        ? _vm.userTableHeaders
                        : _vm.inactiveUserTableHeaders,
                      items: _vm.showingActiveEndUsers
                        ? _vm.endUsers
                        : _vm.inactiveUsers,
                      loading: _vm.userDataLoading,
                      "sort-by": ["email"],
                      "sort-desc": [false],
                      search: _vm.endUserSearch,
                      "custom-filter": _vm.customFilter
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.name",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("span", { staticClass: "data-hj-suppress" }, [
                              _vm._v(" " + _vm._s(item.name) + " ")
                            ])
                          ]
                        }
                      },
                      {
                        key: "item.email.value",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("span", { staticClass: "data-hj-suppress" }, [
                              _vm._v(" " + _vm._s(item.email.value) + " ")
                            ])
                          ]
                        }
                      },
                      {
                        key: "item.tags",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("table-tags-drop-down", {
                              attrs: {
                                tags: _vm.teamTags,
                                item: item,
                                manageTags: "",
                                editable: "",
                                addMultipleTags: "",
                                disabled:
                                  (!_vm.isSuperAdmin &&
                                    !_vm.allowAdminEditingUserTags) ||
                                  item.scimUser
                              },
                              on: {
                                showManageTags: function($event) {
                                  _vm.manageTagsDialog = true
                                },
                                error: function($event) {
                                  _vm.errorMessage = $event
                                }
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "item.actions",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            !_vm.userIsTeamCreator(item)
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: { "offset-y": "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            disabled:
                                                              !_vm.isSuperAdmin ||
                                                              item.scimUser
                                                          }
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-dots-horizontal"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          { attrs: { dense: "" } },
                                          [
                                            _c(
                                              "v-list-item",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editUser(item)
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "account.team.dropDownEdit"
                                                      )
                                                    )
                                                  )
                                                ])
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteUser(item)
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("buttons.delete")
                                                    )
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "item.managedUser",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  left: "",
                                  "max-width": "250",
                                  disabled: !item.scimUser
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-simple-checkbox",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticStyle: {
                                                    cursor: "default"
                                                  },
                                                  attrs: {
                                                    ripple: false,
                                                    value: item.scimUser,
                                                    color: "secondary"
                                                  }
                                                },
                                                "v-simple-checkbox",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "account.team.userSettings.managedUserExplanation"
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }